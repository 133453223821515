<template>
  <vs-card>
    <div slot="header" class="flex flex-no-wrap w-full justify-between items-center">
      <h4>{{ this.name }}'s Permission</h4>
      <vs-button @click="saveData">Save</vs-button>
    </div>
    <div class="p-2">
      <div class="flex items-center space-x-4 mb-6">
        <vs-switch @change="permissionChange" v-model="adminToggle" />
        <h6 v-if="adminToggle">Admin permissions are turned on</h6>
        <h6 v-else>Enable admin permissions to give this staff member access to the below section in your workspace.
        </h6>
      </div>
      <div class="flex flex-col space-y-4 mb-4">
        <vs-checkbox
          class="text-base"
          :class="!adminToggle ? 'mt-2 opacity-50' : 'mt-2'"
          :disabled="!adminToggle"
          v-model="permissions_on"
          vs-value="Staff"
          >Staff management</vs-checkbox
        >
        <vs-checkbox
          class="text-base"
          :class="!adminToggle ? 'mt-2 opacity-50' : 'mt-2'"
          :disabled="!adminToggle"
          v-model="permissions_on"
          vs-value="Clinic"
          >Clinic management</vs-checkbox
        >
        <vs-checkbox
          class="text-base"
          :class="!adminToggle ? 'mt-2 opacity-50' : 'mt-2'"
          :disabled="!adminToggle"
          v-model="permissions_on"
          vs-value="Invoice"
          >Invoice management</vs-checkbox
        >
        <vs-checkbox
          class="text-base"
          :class="!adminToggle ? 'mt-2 opacity-50' : 'mt-2'"
          :disabled="!adminToggle"
          v-model="permissions_on"
          vs-value="Drug"
          >Drugbook management</vs-checkbox
        >
        <vs-checkbox
          class="text-base"
          :class="!adminToggle ? 'mt-2 opacity-50' : 'mt-2'"
          :disabled="!adminToggle"
          v-model="permissions_on"
          vs-value="Shop"
          >Shop - Purchase products</vs-checkbox
        >
        <vs-checkbox
          class="text-base"
          :class="!adminToggle ? 'mt-2 opacity-50' : 'mt-2'"
          :disabled="!adminToggle"
          v-model="permissions_on"
          vs-value="Compliance"
          >Monthly Compliance</vs-checkbox
        >
        <vs-checkbox
          class="text-base"
          :class="!adminToggle ? 'mt-2 opacity-50' : 'mt-2'"
          :disabled="!adminToggle"
          v-model="permissions_on"
          vs-value="Settings"
          >Settings management</vs-checkbox
        >
        <vs-checkbox
          class="text-base"
          :class="!adminToggle ? 'mt-2 opacity-50' : 'mt-2'"
          :disabled="!adminToggle"
          v-model="permissions_on"
          vs-value="Insights"
          >Insights</vs-checkbox
        >
        <vs-checkbox
          class="text-base"
          :class="!adminToggle ? 'mt-2 opacity-50' : 'mt-2'"
          :disabled="!adminToggle"
          v-model="permissions_on"
          vs-value="CustomConsentForm"
          >Consent Form</vs-checkbox
        >
        <vs-checkbox
          v-if="isContourOrg"
          class="text-base"
          :class="!adminToggle ? 'mt-2 opacity-50' : 'mt-2'"
          :disabled="!adminToggle"
          v-model="permissions_on"
          vs-value="CustomProduct"
          >Custom Product</vs-checkbox
        >
        <!-- <vs-checkbox
          v-if="isContourOrg"
          class="text-base"
          :class="!adminToggle ? 'mt-2 opacity-50' : 'mt-2'"
          :disabled="!adminToggle"
          v-model="permissions_on"
          vs-value="CustomTreatmentAreas"
          >Custom Treatment Area</vs-checkbox
        > -->
      </div>

      <div
        v-if="checklist"
        class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
      >
        <span class="span-text-validation"
          >Check at least one(1) permission or turn off admin permission</span
        >
      </div>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["name", "id", "isNurseType"],
  data() {
    return {
      adminToggle: false,
      organizationId: null,
      permissions_on: [],
      permissions_off: [],
      checklist: false,
      orgDetails: {}
    };
  },
  methods: {
    ...mapActions("admin", ["fetchUserPermission", "updateUserPermission"]),
    async getOrganizationComplianceStatus() {
      const organizationId = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
      );
      if (organizationId && this.$store) {
        const { data } = await this.$store.dispatch(
          "organization/fetchOrganizationDetails",
          organizationId
        );

        if (data) this.orgDetails = data.data
      }
    },
    getUserPermission() {
      this.$vs.loading();
      this.fetchUserPermission({
        userId: this.id,
        organizationId: this.organizationId,
      })
        .then((res) => {
          const val = res.data;
          if (val.length > 0) {
            val.map((perm) => {
              this.permissions_on.push(perm.permission.name);
            });
            this.adminToggle = true;
          }
          this.$vs.loading.close();
        })
        .catch((err) => {
          console.log("error", err);
          this.$vs.loading.close();
        });
    },
    permissionChange() {
      if (!this.adminToggle) {
        this.permissions_on = [];
        this.checklist = false;
      }
    },
    saveData() {
      if (this.adminToggle && this.permissions_on.length === 0) {
        this.checklist = true;
      } else {
        this.checklist = false;
        this.$vs.loading();
        let permissionsList = [
          "Invoice",
          "Staff",
          "Clinic",
          "Shop",
          "Settings",
          "Compliance",
          "Drug",
          "Insights",
          "POS",
          "CustomConsentForm"
        ];

        if (this.isContourOrg) {
          permissionsList = [
            ...permissionsList,
            "CustomProduct",
            // "CustomTreatmentAreas"
          ]
        }

        this.permissions_off = _.difference(
          permissionsList,
          this.permissions_on
        );
        const payload = {
          userId: this.id,
          organizationId: this.organizationId,
          permissions_on: this.permissions_on,
          permissions_off: this.permissions_off,
        };
        this.updateUserPermission(payload)
          .then((res) => {
            if (res.status === 200) {
              this.$vs.notify({
                title: "Success",
                text: "Successfully updated user permissions.",
              });
              this.$vs.loading.close();
            }
          })
          .catch((err) => {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update user permissions.",
              color: "danger",
            });
          });
        this.$vs.loading.close();
      }
    },
    getChecklist() {
      if (this.adminToggle && this.permissions_on.length === 0) {
        this.checklist = true;
      }
      this.checklist = false;
    },
  },
  watch: {
    permissions_on: {
      handler() {
        this.getChecklist();
      },
    },
  },
  created() {
    this.organizationId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    this.getUserPermission();
    this.getOrganizationComplianceStatus()
  },
  computed: {
    isContourOrg() {
      return Object.keys(this.orgDetails).length && this.orgDetails.isContourOrg;
    }
  }
};
</script>

<style></style>
